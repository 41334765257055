import Navbar from "../components/Navbar";
import Head from "next/head";
import Image from "next/image";
import mochalogo from "../public/mochalogo.svg";
import Link from "next/link";
import { useState, useEffect, useRef, useCallback } from "react";
import FeatureCard from "../components/FeatureCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AutomatedInternalProcesses, Customize, StockManage } from "./../public/assets/customcsv";

import {
  faAngleLeft,
  faAngleRight,
  faArrowRight,
  faCalendarDays,
  faCheck,
  faCircleCheck,
  faClipboardQuestion,
  faVideoCamera,
} from "@fortawesome/free-solid-svg-icons";
import FAQ from "../components/Faq";
import Footer from "../components/Footer";

export default function Home() {


  const cardContainerRef = useRef(null);


  const scrollToPosition = useCallback((scrollLeft) => {
    if (cardContainerRef.current) {
      cardContainerRef.current.scrollTo({
        left: scrollLeft,
        behavior: 'smooth',
      });
    }
  }, []);
  
  const scrollLeft = useCallback(() => {
    if (cardContainerRef.current) {
      const currentScrollLeft = cardContainerRef.current.scrollLeft;
      const newScrollLeft = currentScrollLeft - 300;
  
     
      if (newScrollLeft <= 0) {
        scrollToPosition(cardContainerRef.current.scrollWidth);
      } else {
        scrollToPosition(newScrollLeft);
      }
    }
  }, [scrollToPosition]);
  
  const scrollRight = useCallback(() => {
    if (cardContainerRef.current) {
      const currentScrollLeft = cardContainerRef.current.scrollLeft;
      const newScrollLeft = currentScrollLeft + 300;
  
     
      if (newScrollLeft + cardContainerRef.current.offsetWidth >= cardContainerRef.current.scrollWidth) {
        scrollToPosition(0);
      } else {
        scrollToPosition(newScrollLeft);
      }
    }
  }, [scrollToPosition]);
  return (
    <div>
      <Navbar />
      <Head>
        <title>Free Accounting Software | Best Accounting Solution | Mocha Accounting</title>
        <meta
          name="description"
          content="Mocha Accounting offers the best free accounting software for managing your finances. Perfect for businesses to handle invoicing, expenses, and more."
        />
        
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />

        <meta name="keywords" content="free accounting software, best accounting software, Mocha Accounting, invoicing, expenses" />
        <meta property="og:title" content="Free & Best Accounting Software - Mocha Accounting" />
        <meta property="og:description" content="Manage your finances with Mocha Accounting, the best free accounting software. Ideal for businesses of all sizes." />
        <meta property="og:image" content={mochalogo} />
        <meta property="og:url" content="https://mochaaccounting.com/" />
        <link rel="canonical" href="https://mochaaccounting.com/" />
        <meta name="robots" content="index, follow" />
        <meta name="description" content="Are you looking for free Accounting provide best accounting solution for small-mid-enterprise business" />
        <link rel="preload" as="image" href="/home/home.webp" />
  <link rel="preload" as="image" href="/bg.svg" />
  <link rel="preload" as="image" href="/home/wave.webp" />
      </Head>

      <div  >

        
      <section className="flex justify-between items-center md:items-start bg-no-repeat bg-center md:bg-[url('/bg.svg')]">
  <div className="mt-[100px] flex flex-col lg:flex-row justify-between items-center lg:items-start w-full">
    <div className="flex-1 p-5 lg:p-10 lg:text-left">
      <span className="lg:text-left font-semibold flex lg:justify-start mb-4 rounded-full overflow-hidden whitespace-nowrap">
        <span className="bg-[#CFECEC] flex items-center text-[16px] px-2 py-[16px] rounded-full">
          Welcome to &nbsp;<b>MOCHA&nbsp;</b>Accounting&nbsp;
          <Image
            src="/home/wave.webp"
            alt="wave"
            width={24}
            height={16}
            className="inline-block ml-1"
           
          />
        </span>
      </span>

      <h1 className="text-[30px] md:text-[48px] font-bold text-black mb-6">
        Cost-Effective, Customizable, Streamlined, Free Accounting Software
      </h1>

      <div className="mb-6">
        <p className="text-[18px] md:text-[24px] font-poppins text-[#676767] mb-6">
          Elevate your accounting game with our intuitive tools. Easily manage Invoices, Expenses, Banking Transactions, and much more.
        </p>

        <div className="flex flex-col items-center lg:items-start text-center lg:text-left mb-6">
          <Link href="https://app.mochaaccounting.com/register">
            <button className="flex items-center px-6 py-3 bg-[#1e3932] text-white text-lg font-semibold rounded-full gap-2 hover:bg-black transition"
               aria-label="try for free"
            >
              Try for FREE
              <FontAwesomeIcon icon={faArrowRight} className="w-4 h-4" />
            </button>
          </Link>
          <p className="text-sm mt-2">No Credit Card Required</p>
        </div>

        <div className="flex justify-center lg:justify-start space-x-5 text-[30px] md:space-x-3 md:text-[64px] font-bold leading-[72px] text-[#006241]">
          <span className="font-medium">Simple</span>
          <span className="font-medium">Secure</span>
          <span className="font-medium">Tailored</span>
        </div>
      </div>
    </div>

    <div className="flex-1 flex flex-col items-center justify-center text-center">
    <Image
  src="/home/home.webp"
  alt="Free Accounting Software"
  width={568}
  height={830}
  className="w-full max-w-[568px] md:max-h-[830px] sm:max-w-[320px] sm:max-h-[480px]"

  sizes="(max-width: 640px) 80vw, (max-width: 768px) 60vw, 568px"
   loading="lazy"
  srcSet="/home/home-320w.webp 320w, /home/home-568w.webp 568w, /home/home-768w.webp 768w"
/>


      <span className="text-lg font-medium mt-4">Free accounting software</span>
    </div>
  </div>
</section>




        <section className="pt-8 pb-[4%] bg-[#fcfcfc] text-[#1c352d] px-6 md:px-16 font-poppins">
          <div className="text-center mb-8">
            <h1 className="text-[30px] md:text-[48px] font-bold leading-[56px]">Unlock The True Potential of Your Business with Mocha Accounting</h1>
          </div>

          <div className="flex flex-wrap justify-center gap-8">
            <div className="flex flex-col max-w-xs w-full border-green-700 border-[1px] rounded-xl transition-transform duration-300 ease-in-out hover:bg-[#006241] hover:text-white">
              <div className="flex items-center p-4">
                <p className="text-lg font-semibold mr-4">Automated Internal Processes</p>
                <div className="wink-0 w-8 h-8">
                  <AutomatedInternalProcesses className="w-full h-full" />
                </div>
              </div>
              <div className="p-4 text-left">
                <p className="text-base font-semibold">Make accounting effortless with <b>MOCHA</b>&#39;s automation tools. From banking to sales to bills - set it up once, and let <b>MOCHA</b> do the rest.</p>
              </div>
            </div>

            <div className="flex flex-col max-w-xs w-full border-green-700 border-[1px] rounded-xl transition-transform duration-300 ease-in-out hover:bg-[#006241] hover:text-white">
              <div className="flex items-center p-4">
                <p className="text-lg font-semibold mr-4">Stock Management</p>
                <div className="flex-shrink-0 w-8 h-8">
                  <StockManage className="w-full h-full" />
                </div>
              </div>
              <div className="p-4 text-left">
                <p className="text-base font-semibold">Transform your business with our intelligent inventory solutions. Precisely track stock movement with our intuitive platform.</p>
              </div>
            </div>

            <div className="flex flex-col max-w-xs w-full  border-green-700 border-[1px] rounded-xl transition-transform duration-300 ease-in-out hover:bg-[#006241] hover:text-white">
              <div className="flex items-center p-4">
                <p className="text-lg font-semibold mr-4">Customize - We Make it a Perfect Fit</p>
                <div className="flex-shrink-0 w-8 h-8">
                  <Customize className="w-full h-full" />
                </div>
              </div>
              <div className="p-4 text-left">
                <p className="text-base font-semibold">All businesses are unique - customize Chart of Accounts, Transaction Categories, and much more to meet your unique business needs!</p>
              </div>
            </div>
          </div>
        </section>



        <section className="bg-[#006241] text-white w-full h-[254px] border-t border-white flex items-center justify-center">
          <div className="flex flex-col md:flex-row items-center text-center md:text-left px-4 gap-4 w-full justify-around">
            <h1 className="font-poppins text-[30px] md:text-[48px] font-bold leading-[40px] text-left">
              Everything your business needs - in one place
            </h1>

            <Link href="/scheduledemo">
              <div className="flex items-center justify-center md:justify-start w-full md:w-auto">
                <button 
                className=" top-[94px] left-[1130px] p-[24px_36px] gap-[8px] flex items-center mx-auto justify-between border border-solid rounded-full  "
                   aria-label="Request Demo"
                >
                  Request a demo
                  <FontAwesomeIcon
                    icon={faVideoCamera}
                    height={"16px"}
                    width={"16px"}
                    className="ml-2"
                  />
                </button>
              </div>
            </Link>
          </div>
        </section>

        <section className="bg-[#FFFFFF] py-5">
          <div className="m-auto ">
            <div className="relative flex items-center">

              <button
                className="absolute left-0 z-1 bg-yellow-600 text-white p-2 rounded-full w-10 h-10 flex items-center justify-center"
                onClick={scrollLeft}
                   aria-label="scroll left"
              >
                <FontAwesomeIcon icon={faAngleLeft} />
              </button>


              <div
                className="flex overflow-x-scroll scrollbar-hide space-x-[63px] py-5 px-4 text-[#0A0B0A] "
                ref={cardContainerRef}
              >

                <div className="flex-shrink-0 flex flex-col items-center p-5 w-[400px] h-[609px] opacity-[1] border border-gray-300 rounded-3xl bg-[#DBDBDB]">
                  <img src="/home/receivables.webp" alt="Receivables" className="h-[300px] w-[400px] mb-4" loading="lazy" />
                  <h2 className="text-[24px] font-bold leading-[32px] text-center font-poppins">Receivables</h2>
                  <p className="text-[18px] font-normal leading-[30px] text-center font-dm-sans mt-2">
                    Raise tax-compliant, professional invoices in no time. Automate invoices, set reminders, and more.
                  </p>
                  <div className="flex-grow"></div>


                  <span className="text-[#006241] font-semibold mt-4 flex items-center gap-2">Learn More <FontAwesomeIcon icon={faArrowRight} className="w-4 h-4" /></span>
                </div>


                <div className="flex-shrink-0 flex flex-col items-center p-5 w-[400px] h-[609px] opacity-[1] border border-gray-300 rounded-3xl bg-[#DBDBDB]">
                  <img src="/home/feat2.webp" alt="Payables" className="h-[300px] w-[400px] mb-4" loading="lazy"/>
                  <h2 className="text-[24px] font-bold leading-[32px] text-center font-poppins">Payables</h2>
                  <p className="text-[18px] font-normal leading-[30px] text-center font-dm-sans mt-2">
                    Track vendor bills and other expenses without any fuss. Manage vendor credits, and more.
                  </p>
                  <div className="flex-grow"></div>


                  <span className="text-[#006241] font-semibold mt-4 flex items-center gap-2">Learn More <FontAwesomeIcon icon={faArrowRight} className="w-4 h-4" /></span>
                </div>


                <div className="flex-shrink-0 flex flex-col items-center p-5 w-[400px] h-[609px] opacity-[1] border border-gray-300 rounded-3xl bg-[#DBDBDB]">
                  <img src="/home/feat1.webp" alt="Robust Dashboard" className="h-[300px] w-[400px] mb-4" loading="lazy"/>
                  <h2 className="text-[24px] font-bold leading-[32px] text-center font-poppins">Robust Dashboard</h2>
                  <p className="text-[18px] font-normal leading-[30px] text-center font-dm-sans mt-2">
                    Harness the power of data with our robust reporting capabilities. Gain valuable insights into your financial performance, track key metrics, and make data-driven decisions.
                  </p>
                  <div className="flex-grow"></div>


                  <span className="text-[#006241] font-semibold mt-4 flex items-center gap-2">Learn More <FontAwesomeIcon icon={faArrowRight} className="w-4 h-4" /></span>
                </div>


                <div className="flex-shrink-0 flex flex-col items-center p-5 w-[400px] h-[609px] opacity-[1] border border-gray-300 rounded-3xl bg-[#DBDBDB]">
                  <img src="/home/usergroups.webp" alt="User Groups" className="h-[300px] w-[400px] mb-4" loading="lazy"/>
                  <h2 className="text-[24px] font-bold leading-[32px] text-center font-poppins">User Groups</h2>
                  <p className="text-[18px] font-normal leading-[30px] text-center font-dm-sans mt-2">
                    Streamline your financial processes by working as a team. Assign specific roles and permissions to team members, ensuring everyone has access to the information they need.
                  </p>
                  <div className="flex-grow"></div>


                  <span className="text-[#006241] font-semibold mt-4 flex items-center gap-2">
                    Learn More <FontAwesomeIcon icon={faArrowRight} className="w-4 h-4" />
                  </span>
                </div>


                <div className="flex-shrink-0 flex flex-col items-center p-5 w-[400px] h-[609px] opacity-[1] border border-gray-300 rounded-3xl bg-[#DBDBDB]">
                  <img src="/home/security.webp" alt="Security & Privacy" className="h-[300px] w-[400px] mb-4" loading="lazy"/>
                  <h2 className="text-[24px] font-bold leading-[32px] text-center font-poppins">Security & Privacy</h2>
                  <p className="text-[18px] font-normal leading-[30px] text-center font-dm-sans mt-2">
                    We use state-of-the-art encryption technology to protect your data. Our commitment to safeguarding your data is reflected in every aspect of our app, ensuring you can manage your finances with complete confidence.
                  </p>
                  <div className="flex-grow"></div>


                  <span className="text-[#006241] font-semibold mt-4 flex items-center gap-2">
                    Learn More <FontAwesomeIcon icon={faArrowRight} className="w-4 h-4" />
                  </span>
                </div>
              </div>


              <button
                className="absolute right-0 z-1 bg-yellow-600 text-white p-2 rounded-full w-10 h-10 flex items-center justify-center"
                onClick={scrollRight}
                aria-label="scroll right"
              >
                <FontAwesomeIcon icon={faAngleRight} />
              </button>
            </div>
          </div>
        </section>




        <section className="bg-[#fcfcfc] py-6 text-[#1c352d]">
          <div className="max-w-7xl mx-auto text-left font-poppins px-4 md:px-8">
            <p className="text-[16px] font-semibold">TAILORED FOR YOU</p>
            <h1 className="text-[30px] md:text-[48px] font-bold leading-[46px] md:leading-[56px] max-w-[800px] mb-6">
              An Accounting Solution for Every Need and Every Business
            </h1>
          </div>

          <div className="flex flex-col md:flex-row justify-between items-center max-w-7xl mx-auto px-4 md:px-8">

            <div className="flex-1 ">
              <div className="border border-[#e49b0f] rounded-2xl p-8 gap-6 bg-white shadow-lg">

                <div className="flex items-center">
                  <Image src="/home/tick.webp" 
                  alt="Small scale business"
                   width={25}
                    height={25}
                    loading="lazy"

                    />
                  <p className="font-bold pl-2 text-[18px]">Small scale business</p>
                </div>
                <p className="text-[18px] text-[#717579] pl-7 leading-[26px]">
                  Get paid on time, automate tasks, and make informed financial decisions.
                </p>


                <div className="flex items-center mt-6">
                  <Image src="/home/tick.webp" alt="Mid-market business" 
                  width={25} height={25}
                 loading="lazy"
                  />
                  <p className="font-bold pl-2 text-[18px]">Mid-market business</p>
                </div>
                <p className="text-[18px] text-[#717579] pl-7 leading-[26px]">
                  Go global! Use multi-currency features, advanced integrations, analytics, and customization.
                </p>


                <div className="flex items-center mt-6">
                  <Image src="/home/tick.svg" alt="Large enterprises" 
                  width={25} height={25}
                    loading="lazy"
                  />
                  <p className="font-bold pl-2 text-[18px]">For large enterprises</p>
                </div>
                <p className="text-[18px] text-[#717579] pl-7 leading-[26px]">
                  Track all business segments, advanced integration, analytics.
                </p>


                <div className="flex justify-center mt-10">
                  <Link href="#">
                    <button className="px-8 py-4 text-[18px] font-semibold bg-[#006241] text-white rounded-full h-[65px]" aria-label="Explore Features">
                      Explore Features
                    </button>
                  </Link>
                </div>
              </div>
            </div>

            <div className="flex-1 px-5 md:px-0 md:mr-[100px] mt-10 md:mt-0 flex justify-center">
            <Image
  src="/home/tailored.webp"
  alt="Tailored Image"
  className="object-contain"
  width={500}
  height={500}
  loading="lazy"
  sizes="(max-width: 768px) 100vw, 500px"
  srcSet="/home/tailored-320w.webp 320w, /home/tailored-500w.webp 500w, /home/tailored-768w.webp 768w"
/>

            </div>
          </div>
        </section>

        <div className="bg-[#f2f0eb] p-8">
          <div className="pb-4 text-center">
            <h1 className="text-[30px] md:text-[48px] font-semibold text-[#006241]">
              Overview of Mocha Accounting
            </h1>
          </div>

          <div className="flex flex-wrap justify-between mx-[100px] md:mx-[70px] pb-12">
            <div className="flex-1 mx-2">
              <ul className="list-none p-0">
                <li className="flex gap-2 p-2 text-left mb-1 text-xl lg:text-2xl">
                  <Image
                    alt="Image description"
                    src="/home/ovwchck.webp"
                    width={25}
                    height={25}
                  loading="lazy"
                  />
                  Sales
                </li>
                <li className="flex gap-2 p-2 text-left mb-1 text-xl lg:text-2xl">
                  <Image
                    alt="Image description"
                    src="/home/ovwchck.svg"
                    width={25}
                    height={25}
                  
                  />
                  Invoices
                </li>
                <li className="flex gap-2 p-2 text-left mb-1 text-xl lg:text-2xl">
                  <Image
                    alt="Image description"
                    src="/home/ovwchck.svg"
                    width={25}
                    height={25}
                   loading="lazy"
                  />
                  Inventory Management
                </li>
              </ul>
            </div>

            <div className="flex-1 mx-2">
              <ul className="list-none p-0">
                <li className="flex gap-2 p-2 text-left mb-1 text-xl lg:text-2xl">
                  <Image
                    alt="Image description"
                    src="/home/ovwchck.svg"
                    width={25}
                    height={25}
                 
                  />
                  Expenses
                </li>
                <li className="flex gap-2 p-2 text-left mb-1 text-xl lg:text-2xl">
                  <Image
                    alt="Image description"
                    src="/home/ovwchck.svg"
                    width={25}
                    height={25}
                    
                  />
                  Purchase Orders
                </li>
                <li className="flex gap-2 p-2 text-left mb-1 text-xl lg:text-2xl">
                  <Image
                    alt="Image description"
                    src="/home/ovwchck.svg"
                    width={25}
                    height={25}
                   
                  />
                  Customizable Reports
                </li>
              </ul>
            </div>

            <div className="flex-1 mx-2">
              <ul className="list-none p-0">
                <li className="flex gap-2 p-2 text-left mb-1 text-xl lg:text-2xl">
                  <Image
                    alt="Image description"
                    src="/home/ovwchck.svg"
                    width={25}
                    height={25}
                    loading="lazy" 
                  />
                  Upload Bank Statements
                </li>
                <li className="flex gap-2 p-2 text-left mb-1 text-xl lg:text-2xl">
                  <Image
                    alt="Image description"
                    src="/home/ovwchck.svg"
                    width={25}
                    height={25}
                    loading="lazy" 
                  />
                  Comprehensive Dashboard
                </li>
                <li className="flex gap-2 p-2 text-left mb-1 text-xl lg:text-2xl">
                  <Image
                    alt="Image description"
                    src="/home/ovwchck.svg"
                    width={25}
                    height={25}
                    loading="lazy" 
                  />
                  GST Compliance
                </li>
              </ul>
            </div>
          </div>
        </div>


        <section className="bg-[#f9f9f9]  mt-[10px] md:py-12 sm:mb-[20px] font-poppins">
          <div className="w-full px-4">
            <div className="flex flex-col sm:flex-row items-center">

              <div className="flex-1  text-center sm:text-left sm:mb-[31px] md:mb-0">
                <h1 className="font-bold  text-[30px] md:text-[48px] mb-5">
                  Our Trusted Partners
                </h1>
              </div>


              <div className="flex flex-col sm:flex-row items-center sm-top sm:items-start gap-12">
                <div className="mb-6 md:mb-0">
                  <Image src="/partners/aws.svg" alt="AWS" 
                  width="100" height="60" 
                  loading="lazy" 
                  />
                </div>
                <div>
                  <Image src="/partners/financial.svg"
                   alt="Financial" width="306" 
                   height="60" 
                   loading="lazy" 
                   />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>


          <div className="mt-10">
            <div >
              <h1 className="text-[30px] md:text-[48px]  ml-5 text-center font-bold"  > Frequently Asked Questions</h1>
            </div>
            <FAQ />

          </div>
        </section>
        {/* check */}


      </div>

      
      <Footer />
    </div>
  );
}
