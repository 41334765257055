// components/FeatureCard.js
import styles from './FeatureCard.module.css';

const FeatureCard = ({ imageSrc, secondImageSrc,thirdImageSrc, title, description, backgroundColor,cardHeight, cardWidth, borderColor,  imageHeight, imageWidth,additionalText, moreText, cardClassName, imageClassName }) => {
  return (
    <div className={`${styles.card} ${cardClassName}`} style={{ backgroundColor, height: cardHeight, borderColor: borderColor }}>
      {imageSrc && (  
        <div className={styles.imageContainer}>
          <img src={imageSrc} alt={title} className={`${styles.image} ${imageClassName}`} />
        </div>
      )}
       {secondImageSrc && thirdImageSrc && (
        <div className={styles.twoImageContainer} style={{ height: imageHeight }}>
          <img src={secondImageSrc} alt={`${title} second`} className={`${styles.image} ${imageClassName}`} />
          <img src={thirdImageSrc} alt={`${title} third`} className={`${styles.image} ${imageClassName}`} />
        </div>
      )}
      <h3 className={`${styles.title} ${styles.customTitle}`}>{title}</h3>
      <p className={styles.description}>{description}</p>
      {additionalText && <p className={styles.additionalText}>{additionalText}</p>}
      {moreText && <p className={styles.moreText}>{moreText}</p>}
    </div>
  );
};

export default FeatureCard;
